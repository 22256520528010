import { Directive, HostListener, ElementRef, OnInit, Input } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { aDecimalConPuntoSinSepMil, deserializarImporte, isNullOrEmptyOrUndefined, serializarImporte } from '../utils/utils.component';



@Directive({
  selector: '[campoDecimalComa]',
})
export class CampoDecimalComa implements OnInit {
  @Input() campoDecimalComa: number;
  @Input() parteEntera: number;
  @Input() seccionItems: boolean = false;
  @Input() permitirVacio: boolean = false;
  @Input() mostrarLiteral: boolean = false;
  private regex: RegExp;
  private specialKeys: string[] = ['Backspace', 'Tab', 'End', 'Home', 'ArrowLeft', 'ArrowRight', 'Del', 'Delete'];
  private caracterDecimal: string;
  private caracterMillar: string;
  constructor(
    private el: ElementRef,
    private translate: TranslateService
  ) { }

  ngOnInit() {
    if (!this.campoDecimalComa) this.campoDecimalComa = 2;
    if (!this.parteEntera) this.parteEntera = 13;
    if (this.translate.defaultLang == 'en-US') {
      this.caracterDecimal = '.';
      this.caracterMillar = ',';
    }
    else {
      this.caracterDecimal = ',';
      this.caracterMillar = '.';
    }
    this.regex = new RegExp(`^\\d{1,${this.parteEntera}}(\\${this.caracterDecimal}\\d{0,${this.campoDecimalComa}})?$`, 'g');
    // this.regex = new RegExp(`^\\d*(\\.?\\d{3})*(\\,?\\d{0,${this.campoDecimalComa}})$`, 'g');
  }

  @HostListener('keypress', ['$event'])
  onKeyPress(event: KeyboardEvent) {
    this.onKeyDown(event);
  }

  @HostListener('keydown', ['$event'])
  onKeyDown(event: KeyboardEvent) {

    // Allow Backspace, tab, end, and home keys
    if (this.specialKeys.indexOf(event.key) !== -1
      // Allow: Ctrl+A
      || (event.key === 'a' && (event.ctrlKey || event.metaKey))
      // Allow: Ctrl+C
      || (event.key === 'c' && (event.ctrlKey || event.metaKey))
      // Allow: Ctrl+V
      || (event.key === 'v' && (event.ctrlKey || event.metaKey))
      // Allow: Ctrl+X
      || (event.key === 'x' && (event.ctrlKey || event.metaKey))) {
      return;
    }
    let current: string = this.el.nativeElement.value;
    const position = this.el.nativeElement.selectionStart;
    const next: string = [current.slice(0, position), event.key == 'Decimal' ? ',' : event.key, current.slice(position)].join('');

    if (next && !String(next).match(this.regex)) {
      event.preventDefault();
    }

    //cambiar el punto por coma y viceversa en ingles
    if (event.key == this.caracterMillar && String(next.replace(this.caracterMillar, this.caracterDecimal)).match(this.regex)) {
      event.preventDefault();
      this.el.nativeElement.value = next.replace(this.caracterMillar, this.caracterDecimal);
      return
    }
  }

  @HostListener('focusout')
  onFocusOut() {
    var element = this.el.nativeElement as HTMLInputElement;
    if(this.mostrarLiteral && !String(element.value).match(this.regex)){
      return;
    }

    if(this.permitirVacio && (element.value == '' || Number(element.value) == 0)){
      this.el.nativeElement.value = '';
      return;
    }
    let valor = element.value == '' ? "0" : (element.value == 'NaN' ? "0" : element.value);
    this.el.nativeElement.value = serializarImporte(deserializarImporte(valor), this.campoDecimalComa, this.seccionItems);
    return;
  }

  @HostListener('focus')
  onFocus() {
    let element = this.el.nativeElement as HTMLInputElement;
    if(this.mostrarLiteral && !String(element.value).match(this.regex)){
      return;
    }

    let valor = (element.value == '' || element.value == 'NaN') ? '' : element.value;
    this.el.nativeElement.value = aDecimalConPuntoSinSepMil(valor).replace('.', ',');
    element.select();
    return;
  }

  @HostListener('paste', ['$event'])
  onPaste(event: ClipboardEvent) {
    let current: string = this.el.nativeElement.value;
    const position = this.el.nativeElement.selectionStart;
    const endPos = this.el.nativeElement.selectionEnd;
    let clipboard: string = event.clipboardData!.getData('text');;
    if (!this.seccionItems) {
      clipboard = clipboard.replace(this.caracterMillar == ',' ? /,/g : /\./g, '');
    }
    const next: string = [current.slice(0, position), clipboard, current.slice(endPos)].join('');
    if (next) {
      if (!String(next).match(this.regex)) {
        event.preventDefault();
        if (this.seccionItems) {
          this.el.nativeElement.value = serializarImporte(0, this.campoDecimalComa, true);
        } else {
          this.el.nativeElement.value = '';
        }
      } else {
        return
      }
    }
  }


  //TO DO Si no funciona el regex para la cantidad de numeros etc, le agregamos esto
  validarCantidadDeCifras(valor: string): boolean {
    let value = deserializarImporte(valor);
    if (value.toString().indexOf('.') != -1) {
      let numero = value.toString().split('.');
      let entero = numero[0];
      let decimal = numero[1] ?? '';

      if (entero.length > this.parteEntera || decimal.length > this.campoDecimalComa) {
        return false
      }
    } else if (value.toString().length > this.parteEntera) {
      return false
    }
    return true;
  }

}
